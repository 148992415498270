<template lang="pug">
	#budgets-root
		#budgets-section
			.widget
				el-card.box-card
					.clearfix.d-flex.justify-content-start(slot="header")
						h3
							b Budgets and BI
					el-tabs(v-model="currentTab" type="" @tab-click="changeTabFunc" )
						el-tab-pane(label="Integrations" name="plt_itg")
							platform-integration.mt-3
						el-tab-pane(label="Campaigns" name="plt_campaigns")
							reports-campaigns.mt-3
						el-tab-pane(label="Aggregated Statistics" name="plt_stats")
							reports-campaign-stats.mt-3
						el-tab-pane(label="Report Builder" name="report_builder")
							p TBD

</template>

<style lang="scss"></style>

<script lang="js">
import PlatformIntegration from './platform-integrations';
import ReportsCampaigns from "@/views/budgets/reports-campaigns.vue";
import ReportsCampaignStats from "@/views/budgets/reports-campaign-stats"
export default {
	components: {
		PlatformIntegration,
		ReportsCampaigns,
		ReportsCampaignStats
	},
	name: 'budgets',
	data() {
		return {
			currentTab: this.$store.getters.PLT_CUR_BUDGETS_TAB || 'plt_itg'
		}
	},
	methods: {
		changeTabFunc($event) {
			this.$store.dispatch('setCurrentBudgetsTab', this.currentTab)

		},
	}
}
</script>