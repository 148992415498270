<template lang="pug">
	#reports-campaign-stats-root
		#reports-campaign-stats-body
			el-row.text-left
				el-form()
					el-row.text-left
						h4 Reports - Campaign Statistics
						p This page has the statistics of the campaigns that are currently running on the platforms that we have integrated with. You can view the campaigns and their performance here as well as to add the geos.

					el-row.mt-2(:gutter="12")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Brand")
								el-select.w-100(filterable="" v-model="filters.brand_id" clearable placeholder="Select Brand" @change="fetchData")
									el-option(label="All Brands", :value="''")
									el-option(v-for="item in brand_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Plafroms")
								el-select.w-100(filterable="" v-model="filters.platform" clearable placeholder="Select Platform" @change="fetchData")
									el-option(label="All Platforms", :value="''")
									el-option(v-for="item in platform_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Funnel Family")
								el-select.w-100(filterable="" v-model="filters.funnel_family_id" clearable placeholder="Select Funnel Family" @change="fetchData")
									el-option(label="All Funnel Families", :value="''")
									el-option(v-for="item in funnel_family_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Country")
								el-select.w-100(filterable="" v-model="filters.country_iso" clearable placeholder="Select Country" @change="fetchData")
									el-option(label="All Countries", :value="''")
									el-option(v-for="item in country_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Language")
								el-select.w-100(filterable="" v-model="filters.language_iso" clearable placeholder="Select Language" @change="fetchData")
									el-option(label="All Languages", :value="''")
									el-option(v-for="item in language_options" :key="item.v" :label="item.t" :value="item.v")
					el-divider
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")
						div
							el-tooltip(content="Upload the calculations manually - using a spreadsheet file (XLSX)")
								el-button(type="primary" icon="el-icon-upload2" @click="uploadFile" size="small") Upload File
							el-tooltip(content="Recalculate the calculations for the selected campaigns")
								el-button(type="warning" icon="el-icon-refresh" @click="recalculate" size="small") Recalculate

					el-table.mt-3(id="rep-cmp-tbl" v-loading="busy" stripe="" @selection-change="handleSelectionChange" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align="center" label="Date" width="100")
							template(slot-scope="scope")
								span {{moment(scope.row.date).format('YYYY-MM-DD')}}
						el-table-column(align="center" label="Brand" width="100")
							template(slot-scope="scope")
								span() {{ scope.row.brand ? scope.row.brand.name : '-' }}
						el-table-column(align="center" label="Funnel Family" width="100")
							template(slot-scope="scope")
								span() {{ scope.row.funnel_family ? scope.row.funnel_family.name : '-' }}
						el-table-column(align="center" label="Niche" width="100")
							template(slot-scope="scope")
								span {{scope.row.funnel_family && scope.row.funnel_family.niche ? scope.row.funnel_family.niche.name : '-'}}
						el-table-column(align="center" label="Country" )
							template(slot-scope="scope")
								div(v-if="scope.row.country")
									span() {{ scope.row.country.name }}
									img.ml-2(style="width:20px" :src="require('../../assets/img/flags/' + scope.row.country.iso.toLowerCase() + '.svg')")
								div(v-else)
									| -
						el-table-column(align="center" label="Language" )
							template(slot-scope="scope")
								span(v-if="scope.row.language")
									| {{ scope.row.language.full }}
								span(v-else)
									i.text-info Unknown
						el-table-column(align="center" label="Belongs To")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.brand && scope.row.brand.user")
									template(slot="content")
										span.d-block <b>Username:</b> {{ scope.row.brand.user.username }}
										span.d-block <b>Full Name:</b> {{ scope.row.brand.user.first_name + ' ' + scope.row.brand.user.last_name }}
										span.d-block <b>Email:</b> {{ scope.row.brand.user.email }}
										span.d-block <b>ID:</b> {{ scope.row.brand.user.ext_id }}
									span.d-block.chooseable() {{ scope.row.brand.user.username }}
								span(v-else="") -
						el-table-column(align="center" label="Traffic Source" )
							template(slot-scope="scope")
								span() {{ scope.row.traffic_source }}
						el-table-column(align="center" label="Actions" :width="120")
							template(slot-scope="scope")
</template>


<style lang="scss">
.upload-campaigns-file {
	.el-upload-dragger {
		width: 100%
	}

	.el-upload.el-upload--text {
		width: 100%
	}
}
</style>


<script>
import moment from "moment/moment";

export default {
	name: 'reports-campaign-stats',
	data() {
		return {
			// data
			selectedEntries: [],
			paginate: {
				keyword: '',
				limit: 10,
				page: 1
			},
			filters: {
				brand_id: '',
				funnel_family_id: '',
				country_iso: '',
				language_iso: '',
				date: [],
				is_active: -1
			},
			list: [],
			count: 0,
			platform_options: [],
			brand_options: [],
			funnel_family_options: [],
			country_options: [],
			language_options: [],
		}
	},
	mounted() {
		this.fetchData();
		this.fetchOptions();
	},
	methods: {
		fetchData() {
			console.log('aaaa')
		},
		fetchOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', {filters: "59a7b7db-0258-410c-a0ba-ea4e639de60f,95e5744a-9964-42d4-ae26-c3e591ecb7e0,49f06cec-46b0-4237-bf9a-d4d3969e5af6,e91556b8-c5da-442f-a0bf-31f2ce567abe,b46a2973-5f33-4d57-8bc2-3eb96bb26ad2"})
					.then(response => {
						this.platform_options = response.pfi_platform_family;
						this.brand_options = response.brand;
						this.funnel_family_options = response.funnel_families;
						this.country_options = response.countries;
						this.language_options = response.languages;
					})
		},
		handleSelectionChange() {
			console.log('aaaa');
		},
		uploadFile: function () {
			console.log('aaaa');
		},
		recalculate: function () {

		}
	}
}
</script>