<template lang="pug">
	#platforms-integration-root
		#platforms-integration-body
			el-row.text-left
				el-form()
					el-row.text-left
						//pre mode: {{ mode }}

						h4 Platforms Integrations

						p You can find the configurations related to the integration and jobs that are using them. Each configuration entry controls the frequency of data fetching and synchronization.&nbsp;
							| You can create, update, toggle and delete the integration configuration entries.
						p Platform key is a unique identifier for the platform (MGID1, MGID2, ADSKEEPER_A71 etc.). Platform family is a group of platforms that share the same integration type. Integration type is the type of integration&nbsp;
							| that is being used. Client type is the type of client that is being used. Cron data fetch is a boolean flag that indicates whether the data fetching job is active or not.&nbsp;
							| Cron data aggregate is a boolean flag that indicates whether the data aggregation job is active or not.
						p.text-success
							i Note: If you create or update an entry, take into account that the relevant files have to exist on the service side.
					el-row.mt-2(:gutter="24")
						el-col(:span="24")
							el-form-item(label="Configs")
								el-button(type="info" icon="el-icon-plus" @click="toggleModal(0)") Add
					el-row.mt-2(:gutter="12")
						el-col(:xs="12" :sm="8" :md="6")
							el-form-item(label="Search...")
								el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

						el-col(:xs="12" :sm="8" :md="4")
							el-form-item(label="Cron Data Fetch")
								el-select.w-100( v-model="filters.cronDataFetch" placeholder="Boolean Flag" @change="fetchData")
									el-option(label="All" value="")
									el-option(label="On" :value="true")
									el-option(label="Off" :value="false")

						el-col(:xs="12" :sm="8" :md="4")
							el-form-item(label="Cron Data Aggregate")
								el-select.w-100( v-model="filters.cronDataAggregate" placeholder="Boolean Flag" @change="fetchData")
									el-option(label="All" value="")
									el-option(label="On" :value="true")
									el-option(label="Off" :value="false")

						el-col(:xs="12" :sm="8" :md="4")
							el-form-item(label="Client Type")
								el-select.w-100( v-model="filters.clientType" placeholder="Lib or REST" @change="fetchData")
									el-option(label="All" value="")
									el-option(v-for="o of options.client_type" :key="o.v" :label="o.t" :value="o.v")

						el-col(:xs="12" :sm="8" :md="4")
							el-form-item(label="Integration Type")
								el-select.w-100(filterable="" v-model="filters.integrationType" placeholder="Integration Type" @change="fetchData")
									el-option(label="All" value="")
									el-option(v-for="o of options.integration_type" :key="o.v" :label="o.t" :value="o.v")

						el-col(:xs="12" :sm="8" :md="4")
							el-form-item(label="Platform Key")
								el-select.w-100(filterable="" v-model="filters.platform" placeholder="Platform" @change="fetchData")
									el-option(label="All" value="")
									el-option(v-for="o of options.platform" :key="o.v" :label="o.t" :value="o.v")

						el-col(:xs="12" :sm="8" :md="4")
							el-form-item(label="Platform Family")
								el-select.w-100(filterable="" v-model="filters.platformFamily" placeholder="Platform Family" @change="fetchData")
									el-option(label="All" value="")
									el-option(v-for="o of options.platform_family" :key="o.v" :label="o.t" :value="o.v")

					el-divider

					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")
						div
							el-tooltip(content="Request a forced fetching of new data from the platforms")
								el-button(size="mini" type="warning" :disabled="multipleSelection.length === 0" @click="bulk('fetch')") Bulk Fetch
							el-tooltip(content="Request a forced aggregation of data upon the fetched from the platform")
								el-button(size="mini" type="warning" :disabled="multipleSelection.length === 0" @click="bulk('aggregate')") Bulk Aggregate
							el-tooltip(content="Change the busy flag off")
								el-button(size="mini" type="info" :disabled="multipleSelection.length === 0" @click="toggleBulkModal('Busy', true)") Toggle Un-Busy
							el-tooltip(content="Change the Immediate flag on/off")
								el-button(size="mini" type="info" :disabled="multipleSelection.length === 0" @click="toggleBulkModal('Immediate', true)") Immediate On/Off
							el-tooltip(content="Click to shrink or expand the table. Expanded table has more columns but less comfortable to see on small screens")
								el-button(size="mini" :type="expandTable ? 'danger' : 'success'" :icon="expandTable ? 'el-icon-minus' : 'el-icon-plus'" @click="setExpandedTable()") {{ expandTable ? 'Collapse' : 'Expand' }}
							el-tooltip(content="Clear Filters")
								el-button(size="mini" type="danger" @click="clearFilters" icon="el-icon-delete") Clear Filters
					el-table.mt-3(id="plt-itg-tbl" v-loading="busy" stripe="" @selection-change="handleSelectionChange" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						//el-table-column(align="center" label="L" :width="50")
							template(slot-scope="scope" )
								font-awesome-icon.icon.alt.text-danger(v-if="scope.row.UPDATE_LOCK" v-b-tooltip.html.hover-right="", title="Update Locked", size="lg", :icon="['fa', 'lock']")
								font-awesome-icon.icon.alt.text-success(v-else="" v-b-tooltip.html.hover-right="", title="Update Unlocked", size="lg", :icon="['fa', 'unlock']")
						el-table-column(type="selection" :width="55" )
						el-table-column(align="center" label="#" :width="200")
							template(slot-scope="scope")
								span() {{ scope.row._id }}
						el-table-column(align="center" label="Timestamp" :width="90" v-if="expandTable")
							template(slot-scope="scope")
								span() {{ scope.row.createdAt | formatDate }}
						el-table-column(align="center" label="Platform Key" )
							template(slot-scope="scope")
								span() {{ scope.row.PLATFORM }}
						el-table-column(align="center" label="Traffic Source"  v-if="expandTable" :width="180")
							template(slot-scope="scope" )

								el-tooltip(v-for="t of scope.row.TRAFFIC_SOURCE_ID.slice(0, 2)")
									//pre {{t}}
									template(slot="content")
										label <b>Name:</b> {{hash.traffic_source[t]}}, <b>ID:</b> {{t}}
										br
									el-tag.mr-1(:key="t" style="padding: 0 8px !important") {{hash.traffic_source[t]}}
								el-tooltip(v-if="scope.row.TRAFFIC_SOURCE_ID.length > 2")
									template(slot="content")
										div(v-for="(t,i) of scope.row.TRAFFIC_SOURCE_ID.slice(2, scope.row.TRAFFIC_SOURCE_ID.length)")
											label <b>Name:</b> {{hash.traffic_source[t]}}, <b>ID:</b> {{t}}
											br
									el-tag() & Other
								//span(v-if="hash.traffic_source[scope.row.TRAFFIC_SOURCE_ID]") {{ hash.traffic_source[scope.row.TRAFFIC_SOURCE_ID] }}
								span.text-info(v-if="scope.row.TRAFFIC_SOURCE_ID.length === 0")
									i None

						el-table-column(align="center" label="Platform Family")
							template(slot-scope="scope")
								span() {{ scope.row.PLATFORM_FAMILY }}
						el-table-column(align="center" label="Integration")
							template(slot-scope="scope")
								el-tooltip(content="Click to show the if the integration files exist on the service")
									span.chooseable(@click="getIntegrationExists(scope.row.INTEGRATION_TYPE)") {{ scope.row.INTEGRATION_TYPE }}

						el-table-column(align="center" label="Cron Data Fetch")
							template(slot-scope="scope")
								font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.CRON.FLAG.DATA_FETCH", :icon="['fas', 'times']")
								font-awesome-icon.icon.alt.text-success(v-if="scope.row.CRON.FLAG.DATA_FETCH", :icon="['fas', 'check']")
						el-table-column(align="center" label="Cron Data Aggregate")
							template(slot-scope="scope")
								font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.CRON.FLAG.DATA_AGGREGATE", :icon="['fas', 'times']")
								font-awesome-icon.icon.alt.text-success(v-if="scope.row.CRON.FLAG.DATA_AGGREGATE", :icon="['fas', 'check']")
						el-table-column(align="center" label="Busy" :width="200")
							template(slot-scope="scope")
								el-tag.mr-1(type="danger" v-if="scope.row.CRON.BUSY.DATA_FETCH") Data Fetch
								el-tag.mr-1(type="warning" v-if="scope.row.CRON.BUSY.DATA_AGGREGATE") Aggregate
								el-tag.mr-1(type="info" v-if="!scope.row.CRON.BUSY.DATA_FETCH && !scope.row.CRON.BUSY.DATA_AGGREGATE") - NONE -
						el-table-column(align="center" label="Immediate Run" :width="200")
							template(slot-scope="scope")
								el-tag.mr-1(type="danger" v-if="scope.row.CRON.IMMEDIATE.DATA_FETCH") Data Fetch
								el-tag.mr-1(type="warning" v-if="scope.row.CRON.IMMEDIATE.DATA_AGGREGATE") Aggregate
								el-tag.mr-1(type="info" v-if="!scope.row.CRON.IMMEDIATE.DATA_FETCH && !scope.row.CRON.IMMEDIATE.DATA_AGGREGATE") - NONE -
						el-table-column(align="center" label="Fetch - Last Run" :width="expandTable ? 'auto' : 120")
							template(slot-scope="scope")
								el-tooltip.chooseable(v-if="scope.row.CRON.LAST_RUN.DATA_FETCH" content="Click to show the results")
									span.small-text(@click="getLastResultList(scope.row.PLATFORM, 1)") {{ scope.row.CRON.LAST_RUN.DATA_FETCH | formatDateTimeShort }}
								span.text-danger.small-text(v-else) Never
						el-table-column(align="center" label="Aggregate - Last Run" :width="expandTable ? 'auto' : 140")
							template(slot-scope="scope")
								el-tooltip.chooseable(v-if="scope.row.CRON.LAST_RUN.DATA_AGGREGATE" content="Click to show the results")
									span.small-text(@click="getLastResultList(scope.row.PLATFORM, 2)") {{ scope.row.CRON.LAST_RUN.DATA_AGGREGATE | formatDateTimeShort }}
								span.text-danger.small-text(v-else) Never
						el-table-column(align="center" label="Comments"  v-if="expandTable" :width="150")
							template(slot-scope="scope")

								span.chooseable(v-if="scope.row.COMMENTS && scope.row.COMMENTS.length > 0" @click="toggleContentModal(scope.row.COMMENTS)") {{ scope.row.COMMENTS.length > 50 ? scope.row.COMMENTS.substring(0, 50) + '...' : scope.row.COMMENTS }}
								span.text-info(v-else) None
						el-table-column(align="center" label="Meta Data"  v-if="expandTable" :width="150")
							template(slot-scope="scope")
								el-tooltip.chooseable(v-if="metaExists(scope.row.META_DATA)" content="Click to show the meta data")
									span.small-text(@click="toggleContentModal(scope.row.META_DATA)") Click To Show
									//span.small-text(@click="toggleContentModal(scope.row.META_DATA)") {{ scope.row.META_DATA.length > 50 ? (scope.row.META_DATA.substring(0, 50) + '...') : scope.row.META_DATA }}
								span.text-info(v-else) None
						el-table-column(align="center" label="Actions" :width="190")
							template(slot-scope="scope" )
								el-tooltip(content="Forcefully fetch data from the platform")
									el-button.small-btn-action.plt-itg(type="warning", icon="el-icon-refresh", size="small", @click="forceRestart(scope.row._id, 1)")
								el-tooltip(content="Forcefully aggregate data from the platform")
									el-button.small-btn-action.plt-itg(type="warning", icon="el-icon-s-data", size="small", @click="forceRestart(scope.row._id, 2)")
								el-tooltip(content="Edit this configuration")
									el-button.small-btn-action.plt-itg(type="info", icon="el-icon-edit", size="small", @click="toggleModal(1, scope.row)")
								el-tooltip(v-if="scope.row.CRON.BUSY.DATA_FETCH || scope.row.CRON.BUSY.DATA_AGGREGATE" content="Un-Busy this configuration")
									el-button.small-btn-action.plt-itg(type="info", icon="el-icon-unlock", size="small", @click="unbusy(scope.row._id)")
								el-tooltip(content="Delete this configuration")
									el-button.small-btn-action.plt-itg(type="danger", icon="el-icon-remove", size="small", @click="removeEntry(scope.row.id)")
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

						el-row.mt-4
							el-col(:span="24")
								label.label-text-gray
									b Total:&nbsp;
									| {{ count | numeral('0,0') }}

						el-row
							el-col(:span="24")
								el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
									el-option(label="5 Per Page", :value="5")
									el-option(label="10 Per Page", :value="10")
									el-option(label="20 Per Page", :value="20")
									el-option(label="50 Per Page", :value="50")
									el-option(label="100 Per Page", :value="100")

		#platforms-integration-modals
			el-dialog.override-width.search-dialog(:title="`${!mode?'Create new':'Edit existing'} entry${mode?` - #${currentEntry._id} (${currentEntry.PLATFORM})`:''}`" :visible.sync='showSetPlatformIntegrationModal', width='75%', center='' @closed="cancelForm")
				el-row.text-left(:gutter="24")
					el-col(:span="24")
						el-row()
							h5 Integration Platform - Configuration Form
							p The configurations below control the statistics and budgets synchronization tool. You can toggle on/off the sync jobs, control the runtime schedule and many more.
							p.text-success
								i Note: {{!mode ? 'Creating a new configuration entry' : 'Changing any of the configuration entries'}} will trigger a restart of the service to apply these changes.
						el-form(:form="form" :model="form" layout="vertical" :rules="rules" ref="form")
							el-row.text-left
								h5 Integration Platform and type
							el-row(:gutter="12")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Platform Key" prop="PLATFORM")
										el-select.w-100(v-model="form.PLATFORM" allow-create="" filterable="" placeholder="Select the platform key")
											el-option(v-for="o of options.platform" :label="o.t" :key="o.v" :value="o.v")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Platform Family" prop="PLATFORM_FAMILY")
										el-select.w-100(v-model="form.PLATFORM_FAMILY" allow-create="" filterable="" placeholder="Select the platform family")
											el-option(v-for="o of options.platform_family" :label="o.t" :key="o.v" :value="o.v")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Integration" prop="INTEGRATION_TYPE")
										el-select.w-100(v-model="form.INTEGRATION_TYPE" allow-create="" filterable="" placeholder="Select the integration type")
											el-option(v-for="o of options.integration_type" :label="o.t" :key="o.v" :value="o.v")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Client Type" prop="CLIENT_TYPE")
										el-select.w-100(v-model="form.CLIENT_TYPE" allow-create="" filterable="" placeholder="Lib, REST or other")
											el-option(v-for="o of options.client_type" :label="o.t" :key="o.v" :value="o.v")

								el-col(:xs="24" :sm=8 :md="12")
									el-form-item(label="Traffic Source ID" prop="TRAFFIC_SOURCE_ID")
										el-select.w-100(multiple="" filterable="" v-model="form.TRAFFIC_SOURCE_ID" placeholder="Traffic Source ID")
											el-option(v-for="o of options.traffic_source" :label="o.t" :key="o.v" :value="o.v")

								el-col(:xs="24" :sm="8" :md="6")
									el-form-item( prop="SLEEP_DURATION")
										template(slot="label")
											span Sleep Duration&nbsp;
												small.text-success ({{form.SLEEP_DURATION/1000}}sec.)
										el-input-number.w-100(v-model="form.SLEEP_DURATION" :min="0" :max="3600000" :step="100" placeholder="Milliseconds gap between requests")

								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(prop="MULTIPLIER" label="Multiplier Constant")
										el-input-number.w-100(v-model="form.MULTIPLIER" :min="0" :max="1000" :step="0.1" placeholder="Multiplier constant")

							el-divider
							el-row.text-left
								h5 Additional Information and misc
							el-row(:gutter="12")

								el-col(:xs="24" :sm="12")
									el-form-item(label="Comments")
										el-input(v-model="form.COMMENTS" type="textarea" placeholder="Comments" rows="4" maxlength="4096" show-word-limit)
								el-col(:xs="24" :sm="12")
									el-form-item.mb-0(label="Meta Data")
									.w-100.p-0
										p The meta data is a JSON object that can be used to store additional information about the configuration. You can use it to store any additional information that is relevant to the configuration.
										el-button(type="info" size="mini" @click="toggleMetaModal") Show Meta Data
							el-divider
							el-row.mt-2.text-left()
								h5 Step 1 - Data Fetch
								p Set or alter the jobs flags and scheduler - control whether to fetch new data from the platform's API and how often to schedule the fetch job.
								p
									b Last run:&nbsp;
									span.text-success(v-if="mode") {{form.CRON.LAST_RUN.DATA_FETCH | formatDateTimeShort}}
									span.text-danger(v-if="!mode") Never
							el-row.mt-2(:gutter="12")
								el-col(:xs="24" :sm="8" :md="6")

									el-popover(
										placement="top-start"
										:width="480"
										title="Cron Jobs"
										trigger="hover"
									)
										el-divider
										label.d-block
											p
												b Description:&nbsp;
												p Cron jobs are scheduled jobs. You can run these jobs based on a scheduler, using either Linux style cron invoker strings or node-cron ones.
												p For reference:&nbsp;
													a(href="https://crontab.guru") Linux Cron Jobs,&nbsp;
													a(href="https://www.npmjs.com/package/node-cron") node-cron syntax

										el-form-item(slot="reference" prop="CRON_FREQUENCY_DATA_FETCH")
											template(slot="label")
												label CRON Schedule&nbsp;
													i.el-icon-question.chooseable.no-deco()
											el-input(placeholder="For example - 0 0 * * *"  v-model="form.CRON.FREQUENCY.DATA_FETCH")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Is Active")
										el-switch.w-100(v-model="form.CRON.FLAG.DATA_FETCH" active-text="On" inactive-text="Off")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Run On Restart")
										el-switch.w-100(v-model="form.CRON.IMMEDIATE.DATA_FETCH" active-text="On" inactive-text="Off")
								el-col(:xs="24" :sm="8" :md="6" v-if="!!mode")
									el-tooltip(content="Can't change the state of this flag - Read only")
										el-form-item(label="Is Busy - Read Only")
											el-switch.w-100(:label="form.CRON.BUSY.DATA_FETCH" active-text="On" inactive-text="Off" :disabled="true")

							el-divider


							el-row.text-left()
								h5 Step 2 - Data Aggregate
								p Set or alter the jobs flags and scheduler - control whether to run data aggregation that was previously fetched from the platforms and how often to schedule the aggragtion job.
								p.text-success
									i Note: The schedule time should differ from the schedule of the first step, otherwise, the result would be unsynchronized
								p
									b Last run:&nbsp;
									span.text-success(v-if="mode") {{form.CRON.LAST_RUN.DATA_AGGREGATE | formatDateTimeShort}}
									span.text-danger(v-if="!mode") Never
							el-row.mt-2(:gutter="12")
								el-col(:xs="24" :sm="8" :md="6")

									el-popover(
										placement="top-start"
										:width="480"
										title="Cron Jobs"
										trigger="hover"
									)
										el-divider
										label.d-block
											p
												b Description:&nbsp;
												p Cron jobs are scheduled jobs. You can run these jobs based on a scheduler, using either Linux style cron invoker strings or node-cron ones.
												p For reference:&nbsp;
													a(href="https://crontab.guru") Linux Cron Jobs,&nbsp;
													a(href="https://www.npmjs.com/package/node-cron") node-cron syntax

										el-form-item(slot="reference" prop="CRON_FREQUENCY_DATA_AGGREGATE")
											template(slot="label")
												label CRON Schedule&nbsp;
													i.el-icon-question.chooseable.no-deco()
											el-input(placeholder="For example - 0 0 * * *"  v-model="form.CRON.FREQUENCY.DATA_AGGREGATE")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Is Active")
										el-switch.w-100(v-model="form.CRON.FLAG.DATA_AGGREGATE" active-text="On" inactive-text="Off")
								el-col(:xs="24" :sm="8" :md="6")
									el-form-item(label="Run On Restart")
										el-switch.w-100(v-model="form.CRON.IMMEDIATE.DATA_AGGREGATE" active-text="On" inactive-text="Off")
								el-col(:xs="24" :sm="8" :md="6" v-if="!!mode")
									el-tooltip(content="Can't change the state of this flag - Read only")
										el-form-item(label="Is Busy - Read Only")
											el-switch.w-100(:label="form.CRON.BUSY.DATA_AGGREGATE" active-text="On" inactive-text="Off" :disabled="true")

							el-divider

							el-row.text-left()
								h5 Database Collections
								p Set the collections that will be used to store the data that is fetched from the platforms' API - Make sure to choose the type as the integration files rely on it in some places.
								p Default types are:
								ul
									li <b>CAMPAIGN</b> - List of campaigns that are or were in use.
									li <b>INSIGNT</b> - List of campaign statistics that are relevant to the campaigns mentioned above.

							el-row.mt-2(:gutter="24")
								el-col(:span="24")
									el-form-item(label="ADD")
										el-button(type="info" icon="el-icon-plus" @click="addRow('COLLECTIONS', 'NAME', 'TYPE')") Add
							el-row(:gutter="24")
								el-col(:span="24")
									// pre {{form.COLLECTIONS}}
									el-empty.p-0(description="No Records..." :image-size="50" v-if="form.COLLECTIONS.length === 0")
									el-row.mt-1.mb-1(v-for="(c, i) of form.COLLECTIONS" :gutter="6")
										el-col(:xs="12" :sm="6")
											el-input(v-model="c.NAME" placeholder="Name")
										el-col(:xs="12" :sm="8")
											el-select.w-100(v-model="c.TYPE" allow-create="" filterable="" placeholder="Select or type the collection type")
												el-option(value="CAMPAIGN" key="CAMPAIGN")
												el-option(value="INSIGHT" key="INSIGHT")
										el-col(:span="1")
											el-tooltip(content="Remove this entry")
												el-button(icon="el-icon-delete" type="danger" @click="() => form.COLLECTIONS.splice(i,1)")
							el-divider

							el-row.text-left()
								h5 Integration Links
								p Set the hostname and other links to fetch the necessary data. The integration files on the service will use these links to fetch data.
							el-row.mt-2(:gutter="24")
								el-col(:span="24")
									el-form-item(label="ADD")
										el-button(type="info" icon="el-icon-plus" @click="addRow('API')") Add
							el-row(:gutter="24")
								el-col(:span="24")
									el-empty.p-0(description="No Records..." :image-size="50" v-if="form.API.length === 0")
									el-row.mt-1.mb-1(v-for="(o, i) of form.API" :gutter="6")
										el-col(:span="8")
											el-input(v-model="o.key" placeholder="Key")
										el-col(:span="14")
											el-input(v-model="o.value" placeholder="Value")
										el-col(:span="1")
											el-tooltip(content="Remove this entry")
												el-button(icon="el-icon-delete" type="danger" @click="() => form.API.splice(i,1)")

							el-divider

							el-row.text-left()
								h5 Integration Creds
								p Set the Credentials that are necessary to authorize to the platforms. The integration files on the service will use them to fetch data.
							el-row.mt-2(:gutter="24")
								el-col(:span="24")
									el-form-item(label="ADD")
										el-button(type="info" icon="el-icon-plus" @click="addRow('CREDS')") Add
							el-row(:gutter="24")
								el-col(:span="24")
									el-empty.p-0(description="No Records..." :image-size="50" v-if="form.CREDS.length === 0")
									el-row.mt-1.mb-1(v-for="(o, i) of form.CREDS" :gutter="6")
										el-col(:span="8")
											el-input(v-model="o.key" placeholder="Key")
										el-col(:span="14")
											el-input(v-model="o.value" placeholder="Value")
										el-col(:span="1")
											el-tooltip(content="Remove this entry")
												el-button(icon="el-icon-delete" type="danger" @click="() => form.CREDS.splice(i,1)")
							el-divider

							el-row.mt-3(:gutter="12")
								h5.header-text Proxy Settings
								p Set Proxy server settings. You can force the integration to use a proxy server if you have an access to a one. Currently, only <b>HTTP</b> and <b>SOCKS5</b> servers are supported, <b>HTTPS</b> might not work.
							el-row.mt-3(:gutter="12")
								el-col(:span="24")
									el-form-item(label="Toggle Proxy")
										el-switch.w-100.d-inline(v-model="form.PROXY.TOGGLE" active-text="On" inactive-text="Off")
							el-row.mt-3(:gutter="12")
								el-col(:xs="24" :md="8")
									el-form-item(label="Protocol" :error="form.PROXY.PROTOCOL === 'HTTPS' ? 'HTTPS currently not supported!' : ''" )
										el-select.w-100(v-model="form.PROXY.PROTOCOL" :disabled="!form.PROXY.TOGGLE")
											el-option(label="SOCKS5" value="SOCKS5" selected="")
											el-option(label="HTTP" value="HTTP")
											el-option(label="HTTPS" value="HTTPS")
								el-col(:xs="24" :md="10" :lg="11")
									el-form-item(label="Host")
										el-input(v-model="form.PROXY.HOST" :disabled="!form.PROXY.TOGGLE" placeholder="IPv4 address of the proxy" )
								el-col(:xs="24" :md="6" :lg="5")
									el-form-item(label="Port"  prop="integration_facebook_proxy_port")
										el-input-number.w-100(v-model="form.PROXY.PORT" controls-position="right" :min="0" :max="65536" :disabled="!form.PROXY.TOGGLE")

								el-col(:xs="24" :md="8" :lg="6")
									el-form-item(label="Toggle Auth")
										el-switch.w-100.d-inline(v-model="form.PROXY.AUTH.TOGGLE" active-text="On" inactive-text="Off" :disabled="!form.PROXY.TOGGLE")
							el-row(:gutter="24")
								el-col(:xs="24" :md="8" :lg="9")
									el-form-item(label="Auth - Username")
										el-input(v-model="form.PROXY.AUTH.USERNAME" :disabled="!form.PROXY.TOGGLE || !form.PROXY.AUTH.TOGGLE" placeholder="Username" )
								el-col(:xs="24" :md="8" :lg="9")
									el-form-item(label="Auth - Password")
										el-input(v-model="form.PROXY.AUTH.PASSWORD" :disabled="!form.PROXY.TOGGLE || !form.PROXY.AUTH.TOGGLE" placeholder="Password"  type="password" show-password="")


						el-divider
						el-row.mt-3()
							el-col(:span="24" )
								.d-flex.justify-content-center
									el-button(type="success" @click.prevent.native="submit" ) Save
									el-button(type="info" @click.prevent.native="resetForm" ) Reset
									el-button(type="danger" @click.prevent.native="cancelForm" ) Close
			el-dialog(title="Toggle Busy Flags" :visible.sync="showBusyModal" center="" @closed="toggleBulkModal('Busy', false)")
				el-row(:gutter="12")
					p Are you sure you want to toggle the busy flag for the selected entries? This action will change the busy flag for the selected entries.
					p You must choose the type of the job action that the change will run on
				el-form()
					el-row(:gutter="12")
						el-form-item(label="Choose the type of the job action")
							el-checkbox-group(v-model="bulk_type" )
								el-checkbox-button(label="fetch" :value="1") Data Fetch
								el-checkbox-button(label="aggregate" :value="2") Data Aggregate
				el-row.mt-3()
					el-col(:span="24" )
						.d-flex.justify-content-center
							el-button(type="primary" @click.prevent.native="bulkNoModal('busy')" :disabled="bulk_type.length === 0") Run
							el-button(type="danger" @click.prevent.native="toggleBulkModal('Busy',false)" ) Close
				// pre {{bulk_type}}
			el-dialog(title="Toggle Immediate Flags" :visible.sync="showImmediateModal" center="" @closed="toggleBulkModal('Immediate', false)")
				el-row(:gutter="12")
					p Are you sure you want to toggle the immediate flag for the selected entries? This action will change the immediate flag for the selected entries.
					p You must choose the type of the job action that the change will run on
				el-form()
					el-row(:gutter="12")
						el-form-item(label="Choose the type of the job action")
							el-checkbox-group(v-model="bulk_type" )
								el-checkbox-button(label="fetch" :value="1") Data Fetch
								el-checkbox-button(label="aggregate" :value="2") Data Aggregate
						el-form-item(label="Toggle On/Off")
							el-switch(v-model="bulk_flag" active-text="On" inactive-text="Off")
				el-row.mt-3()
					el-col(:span="24" )
						.d-flex.justify-content-center
							el-button(type="primary" @click.prevent.native="bulkNoModal('immediate', bulk_flag)" :disabled="bulk_type.length === 0") Run
							el-button(type="danger" @click.prevent.native="toggleBulkModal('immediate',false)" ) Close
			el-dialog.override-width.search-dialog(:title="`Integration Files Exist - ${chosenIntegrationType}`" :visible.sync='showIntegrationFileExistModal' @closed="() => this.showIntegrationFileExistModal = false")
				el-row.text-left(:gutter="24")
					el-col(:span="24")
						el-row()
							h5 Integration Files Status - Service
							p The following files exist on the service side. If they do not exist, the data won't be fetched from the platforms.

						el-form
							el-row.mt-2(:gutter="12")
								el-col(:xs="24" :sm="8" v-for="e in chosenIntegrationTypeObject")
									el-form-item(:label="`Files ${e.key}`")
										el-tooltip(content="You cannot change this value - Read only")
											el-switch.w-100.is-disabled(:value="e.value" active-text="Exists" inactive-text="Not Exists")
										//el-tag.mr-1(v-for="e in chosenIntegrationTypeObject" :key="e.key" :type="e.value ? 'success' : 'danger'") {{e.key}} - {{e.value ? 'Exists' : 'Not Exists'}}
						el-row.mt-2(:gutter="12")
							el-col(:span="24")
								el-form-item(label="Actions")
									el-button(type="danger" @click="() => this.showIntegrationFileExistModal = false") Close

			el-dialog.override-width.search-dialog(:title="'Existing Last Responses'" :visible.sync='showLastResultsModal', width='75%', center='' @closed="closeLastResultModal")
				el-row.text-left(:gutter="24")
					el-col(:span="24")
						el-row()
							h5 Existing Responses for platform {{lastResultsMeta.platform}} and type {{lastResultsMeta.type}}
							p The followibng responses are the last responses that were fetched from the platform. You can use them to check if the integration is working properly. The results are in a type of a spreadsheet in an Excel file (xslx).
				el-form.mt-3()
					el-row(:gutter="24")
						el-empty(description="No Records..." :image-size="50" v-if="lastResults.length === 0")
						el-col(v-for="f of lastResults" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" )
							el-form-item()
								template(slot="label")
									.d-flex.flex-column
										span.text-center(style="line-height: 1rem") {{f.collection}}
										small.text-center.text-success(style="line-height: 1.3rem") {{f.mtime | formatDateTimeShort}}
								el-tooltip
									template(slot="content")
										span Download the response for: {{f.mtime | formatDateTimeShort}}
									el-button(type="success" @click="downloadLastResult(f.full_name, lastResultsMeta.type)") Download

</template>

<style lang="scss">
.plt-itg {
	&.small-btn-action {
		//padding: 9px 11px
		padding: 6.5px;
		margin-left: 0.3rem !important;

		&:first-child {
			margin-left: 0;
		}
	}
}
</style>

<script>

import {validateAlphaNumeric, validateAlphaNumericAndExists, validateIsInteger} from "@/utils/validate";
import moment from "moment";

const $form = {
	PLATFORM: '',
	PLATFORM_FAMILY: '',
	INTEGRATION_TYPE: '',
	CLIENT_TYPE: '',
	CREDS: [],
	API: [],
	SLEEP_DURATION: 500,
	UPDATE_LOCK: false,
	MULTIPLIER: 1,
	TRAFFIC_SOURCE_ID: '',
	IS_DELETED: false,
	COLLECTIONS: [],
	COMMENTS: '',
	META_DATA: {},
	CRON: {
		FLAG: {
			DATA_FETCH: false,
			DATA_AGGREGATE: false,
		},
		FREQUENCY: {
			DATA_FETCH: '0 0 * * *',
			DATA_AGGREGATE: '0 12 * * *'
		},
		IMMEDIATE: {
			DATA_FETCH: false,
			DATA_AGGREGATE: false
		},
		BUSY: {
			DATA_FETCH: false,
			DATA_AGGREGATE: false,
		},
		LAST_RUN: {
			DATA_FETCH: moment().format(),
			DATA_AGGREGATE: moment().format()
		}
	},
	PROXY: {
		TOGGLE: false,
		PROTOCOL: '',
		HOST: '',
		PORT: '',
		AUTH: {
			TOGGLE: false,
			USERNAME: '',
			PASSWORD: ''
		}
	}
};

const $originalFilters = {
	cronDataFetch: '',
	cronDataAggregate: '',
	clientType: '',
	integrationType: '',
	platform: '',
	platformFamily: ''
}

function clearNulls(body) {
	const data = JSON.parse(JSON.stringify(body));
	for (let key in data) {
		if (data[key] === null || data[key] === '') {
			delete data[key];
		}
	}
	return data;
}

function preparePayload(body) {
	const data = JSON.parse(JSON.stringify(body));
	if(data.CREDS && Array.isArray(data.CREDS)) {
		data.CREDS.forEach((e,i) => {
			if(Array.isArray(e.value)) data.CREDS[i].value = e.value.join(',');
			if(!Number.isNaN(+e.value)) data.CREDS[i].value = `${e.value}`;
		});
	}
	if(data.COLLECTIONS.length > 0) data.COLLECTIONS.forEach((e,i) => {
		delete data.COLLECTIONS[i]._id;
		delete data.COLLECTIONS[i].id;
	});
	delete data.CRON.LAST_RUN;
	delete data.UPDATE_LOCK;
	delete data.__v;
	delete data.createdAt;
	delete data.updatedAt;
	return data;
}

export default {
	name: 'platforms-integration',
	data() {
		return {
			expandTable: this.$store.getters.PLT_ITG_TBL_EXPANDED,
			modalBusy: false,
			block: false,
			fetchWatcher: null,
			showBusyModal: false,
			showImmediateModal: false,
			showLastResultsModal: false,
			chosenIntegrationType: null,
			chosenIntegrationTypeObject: [],
			showIntegrationFileExistModal: false,
			showSetPlatformIntegrationModal: false,
			currentEntry: null,
			multipleSelection: [],
			list: [],
			count: 0,
			form: JSON.parse(JSON.stringify($form)),
			originalConfigForm: JSON.parse(JSON.stringify($form)),
			lastResults: [],
			bulk_type: [],
			bulk_flag: false,
			lastResultsMeta: {
				platform: '',
				type: ''
			},
			counter: {
				API: 1,
				CREDS: 1,
				COLLECTIONS: 1
			},
			paginate: {
				keyword: '',
				limit: 10,
				page: 1
			},
			filters: {
				cronDataFetch: this.$store.getters.CUR_BUDGET_FILTERS.cronDataFetch || '',
				cronDataAggregate: this.$store.getters.CUR_BUDGET_FILTERS.cronDataAggregate || '',
				clientType: this.$store.getters.CUR_BUDGET_FILTERS.clientType || '',
				integrationType: this.$store.getters.CUR_BUDGET_FILTERS.integrationType || '',
				platform: this.$store.getters.CUR_BUDGET_FILTERS.platform || '',
				platformFamily: this.$store.getters.CUR_BUDGET_FILTERS.platformFamily || '',
			},
			options: {
				platform: [],
				platform_family: [],
				integration_type: [],
				client_type: [],
				traffic_source: []
			},
			hash: {
				traffic_source: {}
			},
			rules: {
				PLATFORM: { required: true, trigger: 'change', validator: validateAlphaNumericAndExists, topic: 'platform-integration', app: this, },
				PLATFORM_FAMILY: { required: true, trigger: 'blur', validator: validateAlphaNumeric },
				INTEGRATION_TYPE: { required: true, trigger: 'blur', validator: validateAlphaNumeric },
				CLIENT_TYPE: { required: true, trigger: 'blur', validator: validateAlphaNumeric },
				SLEEP_DURATION: { required: true, trigger: 'blur', validator: validateIsInteger },
				CRON_FREQUENCY_DATA_FETCH: { required: true, trigger: 'blur', validator: validateAlphaNumeric },
				CRON_FREQUENCY_DATA_AGGREGATE: { required: true, trigger: 'blur', validator: validateAlphaNumeric },
			},
			busy: false,
			mode: 0, // 0 - create, 1 - update,
		}
	},
	mounted() {
		this.fetchData();
		this.fetchOptions();
		this.fetchWatcher = setInterval(() => {
			this.fetchData();
		}, 5000);
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher);
		this.fetchWatcher = null;
	},
	methods: {
		metaExists(content) {
			return content && (Array.isArray(content) && content.length > 0 ||  Object.keys(content).length > 0)
		},
		toggleBulkModal(type, flag) {
			this.bulk_type = [];
			this[`show${type}Modal`] = typeof flag !== 'undefined' ? flag : !this[`show${type}Modal`];
		},
		toggleModal(mode, entry) {
			this.mode = mode;
			this.showSetPlatformIntegrationModal = true;
			this.form = JSON.parse(JSON.stringify($form));
			if (mode) {
				this.modalBusy = true;
				this.currentEntry = entry;
				this.form = JSON.parse(JSON.stringify(entry));
				this.rules.PLATFORM.original = entry.PLATFORM;
				this.rules.PLATFORM.action = 'edit';
			} else {
				this.form = JSON.parse(JSON.stringify($form));
				this.rules.PLATFORM.original = entry.PLATFORM;
				this.rules.PLATFORM.action = 'create';
			}
			this.originalConfigForm = JSON.parse(JSON.stringify(this.form))
		},
		fetchData() {
			if(this.modalBusy) return console.log('busy');
			if(this.block) return console.log('checked');

			this.$store.dispatch('setBudgetFilters', this.filters)

			setTimeout(() => {
				const payload = clearNulls({...this.paginate, ...this.filters});

				this.$apix.sendHttpRequest('GET', 'platform-integration/list', payload)
					.then((res) => {
						res.rows.forEach((e,i) => {
							if(res.rows[i] && res.rows[i].API)
								res.rows[i].API = Object.entries(res.rows[i].API).map(row => ({key: row[0], value: row[1]}));
							else if (res.rows[i] && !res.rows[i].API) res.rows[i].API = [];
							if(res.rows[i] && res.rows[i].CREDS)
								res.rows[i].CREDS = Object.entries(res.rows[i].CREDS).map(row => ({key: row[0], value: row[1]}));
							else if (res.rows[i] && !res.rows[i].CREDS) res.rows[i].CREDS = [];
						});
						this.list = res.rows
						// console.log(res.rows)

						this.count = res.count;
				})
			}, 200)
		},
		fetchOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: '7141fd9b-916b-4788-b7a6-b87d19b6438a,fa00bbd8-e805-4a97-8c9a-72e32a646ec5,59a7b7db-0258-410c-a0ba-ea4e639de60f,e3efbf27-571e-4593-967d-aebbb7141de9,e18c637e-8098-47a3-92aa-168b2b1fcab3' })
				.then((res) => {
					//this.options = res;
					// console.log(res)
					this.options.platform = res.pfi_platform;
					this.options.platform_family = res.pfi_platform_family;
					this.options.integration_type = res.pfi_integration_type;
					this.options.client_type = res.pfi_client_type;
					this.options.traffic_source = res.traffic_sources;
					this.hash.traffic_source = {};
					res.traffic_sources.forEach(e => {
						this.hash.traffic_source[e.v] = e.t;
					});
				})
		},
		unbusy(id) {
			this.$confirm('Are you sure that you want to un-busy this entry? This operation will remove the busy lock from the jobs and can lead to duplicate running jobs!', 'Platform Integration Alert')
				.then(async () => {
					this.busy = true;
					return this.$apix.sendHttpRequest('PUT', 'platform-integration/unbusy/' + id)
						.then(resp => {
							this.busy = false;
							if (resp.success) this.$notify.success({ title: 'API Response', message: 'the Platform Integration entry was un-busied successfully' })
							this.fetchData()
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
				})
				.catch(err => {
					console.error(err);
					this.busy = false;
				});
		},
		forceRestart(id, step) {
			this.$confirm(`Are you sure that you want to forcefully restart the data ${step == '1' ? 'fetching' : 'aggregation'} job? This operation cannot be undone!`, 'Force Restart Alert')
				.then(async () => {
					this.busy = true;
					return this.$apix.sendHttpRequest('POST', 'platform-integration/resync-job', {id, step})
						.then(resp => {
							this.busy = false;
							if (resp.success) this.$notify.success({ title: 'API Response', message: 'the Platform Integration entry was forcefully restarted successfully' })
							this.fetchData()
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
				})
				.catch(err => {
					console.error(err);
					this.busy = false;
				});
		},
		submit() {
			this.$confirm(`Are you sure that you want to ${!this.mode ? 'create a new' : 'update the existing'} entry? This operation cannot be undone!
			Note: This will re-run all the jobs that are set to run immidiatly, turn this flag of to avoid unnecerssary runs.`, 'Platform Integration Alert')
				.then(async () => {
					this.busy = true;
					let loader = this.$loading.show();
					try {
						if (!this.mode) {
							let result = await this.$apix.sendHttpRequest('POST', `platform-integration/create`, (this.form))
							if (result.created) {
								this.$notify.success({ title: 'API Response', message: 'the Platform Integration entry was created successfully' })
								this.modalBusy = false;
								this.fetchData();
								this.cancelForm();
								setTimeout(() => loader.hide(), 3000);
							} else {
								this.$notify.warning({ title: 'API Response', message: 'the Platform Integration entry was not created. Result: ' + JSON.stringify(result)  })
								loader.hide();
							}
						} else {
							let result = await this.$apix.sendHttpRequest('PUT', `platform-integration/update/${this.currentEntry._id}`, preparePayload(this.form))
							if (result.modified) {
								this.$notify.success({ title: 'API Response', message: 'the Platform Integration entry was updated successfully' })
								this.modalBusy = false;
								this.fetchData();
								this.cancelForm();
								setTimeout(() => loader.hide(), 3000);
							} else {
								this.$notify.warning({ title: 'API Response', message: 'the Platform Integration entry was not updated. Result: ' + JSON.stringify(result)  })
								loader.hide();
							}
						}
					} catch (e) {
						console.error(e);
						loader.hide();
					}
					this.busy = false;
				})
				.catch(err => {
					console.error(err);
					this.busy = false;
				});
		},
		removeEntry(id) {
			return this.$notify({title: 'API Response', message: 'This operation is not allowed. Please, contact the administrator', type: 'warning'});

/*			this.$apix.sendHttpRequest('DELETE', 'platform-integration/' + id)
				.then(() => {
					this.fetchData();
				})*/
		},
		cancelForm() {
			// this.currentEntry = null;
			this.modalBusy = false;
			this.showSetPlatformIntegrationModal = false;
			this.form = JSON.parse(JSON.stringify($form));
		},
		resetForm() {
			this.form = JSON.parse(JSON.stringify(this.originalConfigForm));
		},
		addRow(type, paramKey = 'key', paramVal = 'value') {
			this.form[type].push({[paramKey]: `${paramKey}_${this.counter[type]}`, [paramVal]: `${paramVal}_${this.counter[type]}`});
			this.counter[type]++;
		},
		getIntegrationExists(type) {
			this.chosenIntegrationType = type;
			this.showIntegrationFileExistModal = true;
			this.$apix.sendHttpRequest('GET', 'platform-integration/integration-exists/', {topic: type})
				.then((res) => {
					this.chosenIntegrationTypeObject = Object.entries(res).map(e => ({key: e[0], value: e[1]}));
				})
		},
		closeIntegrationExistsModal() {
			this.showIntegrationFileExistModal = false;
			this.chosenIntegrationType = null;
			this.chosenIntegrationTypeObject = [];
		},
		setExpandedTable() {
			this.$store.dispatch('setPltItgTblExpanded', !this.expandTable);
			this.expandTable = !this.expandTable;
		},
		getLastResultList(platform, type) {
			this.$apix.sendHttpRequest('GET', 'platform-integration/list-responses', { platform, type })
				.then((res) => {
					this.lastResults = res;
					this.showLastResultsModal = true;
					this.lastResultsMeta.platform = platform;
					this.lastResultsMeta.type = type;
				})
		},
		closeLastResultModal() {
			this.showLastResultsModal = false;
			this.lastResults = [];
			this.lastResultsMeta.platform = null;
			this.lastResultsMeta.type = null
		},
		toggleMetaModal() {
			const condition = this.form.META_DATA && (Array.isArray(this.form.META_DATA) && this.form.META_DATA.length > 0 ||  Object.keys(this.form.META_DATA).length > 0)
			this.$alert(condition ? `<pre>${JSON.stringify(this.form.META_DATA, '<br>', 2)}</pre>` : 'No Meta Data', 'Meta Data', {
				confirmButtonText: 'Close',
				showClose: false,
				dangerouslyUseHTMLString: true,
				callback: action => {
					//this.$message({
					//  type: 'info',
					//  message: `action: ${ action }`
					//});
				}
			});
		},
		toggleContentModal(content) {
			const condition = content && (Array.isArray(content) && content.length > 0 ||  Object.keys(content).length > 0)
			this.$alert(condition ? `<pre>${JSON.stringify(content, '<br>', 2)}</pre>` : 'No Meta Data', 'Meta Data', {
				confirmButtonText: 'Close',
				showClose: false,
				dangerouslyUseHTMLString: true,
				callback: action => {
					//this.$message({
					//  type: 'info',
					//  message: `action: ${ action }`
					//});
				}
			});
		},
		downloadLastResult(fileName, type) {
			return new Promise((resolve, reject) => {
				this.$http.get('platform-integration/result-file', { params: {fileName, type} },{ responseType: 'arraybuffer'})
						.then(response => {

							let decode = new Buffer(response.body, 'base64')
							let blob = new Blob([decode], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
									url = window.URL.createObjectURL(blob)
							window.open(url)
							this.$notify.success({title: 'API Response',message: 'Download succeeded'})
						})
						.catch(e => {
							console.error(['e'], e);
							this.$notify.error({title: 'API Error', message: e.body ? e.body.msg : e.message || 'Could not download spreadsheet'})
						})
			});
		},
		handleSelectionChange(val) {
			this.block =  val.length > 0
			this.multipleSelection = val;
		},
		uncheckSelection() {
			this.multipleSelection = [];
			this.block = false;
		},
		bulkNoModal(action, flag) {
			const $additional = {};
			if(typeof flag !== 'undefined') $additional.flag = flag;
			if(this.bulk_type.length > 0) $additional.type = this.bulk_type;
			this.busy = true;
			return this.$apix.sendHttpRequest('POST', 'platform-integration/bulk-action', { ids: this.multipleSelection.map(e => e._id), action, ...$additional })
				.then(resp => {
					this.busy = false;
					if (resp.success) this.$notify.success({ title: 'API Response', message: 'The bulk action was successfully run on the entries' })
					this.uncheckSelection();
					console.log(`show${action.toCapitalCase()}Modal`)
					this[`show${action.toCapitalCase()}Modal`] = false;
					this.bulk_type = [];
					this.modalBusy = false;
					setTimeout(() => this.fetchData(), 1500);
				})
				.catch(err => {
					console.error(err);
					this.busy = false;
				});
		},
		bulk(action, flag) {
			const $additional = {};
			if(typeof flag !== 'undefined') $additional.flag = flag;
			if(this.bulk_type.length > 0) $additional.type = this.bulk_type;
			this.$confirm(`Are you sure that you want to this action ("${action}") on the selected entries? This operation cannot be undone!`, 'Bulk action Alert')
				.then(async () => {
					this.busy = true;
					return this.$apix.sendHttpRequest('POST', 'platform-integration/bulk-action', { ids: this.multipleSelection.map(e => e._id), action, ...$additional })
						.then(resp => {
							this.busy = false;
							if (resp.success) this.$notify.success({ title: 'API Response', message: 'The bulk action was successfully run on the entries' })
							this.uncheckSelection();
							console.log(`show${action.toCapitalCase()}Modal`)
							this[`show${action.toCapitalCase()}Modal`] = false;
							this.bulk_type = [];
							this.modalBusy = false;
							setTimeout(() => this.fetchData(), 1500);
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
				})
				.catch(err => {
					console.error(err);
					this.busy = false;
				});
		},
		clearFilters() {
			this.$store.dispatch('removeBudgetFilters');
			this.filters = JSON.parse(JSON.stringify($originalFilters));
			this.fetchData();
		}
	}
}
</script>




