<template lang="pug">
	#platforms-integration-root
		#platforms-integration-body
			el-row.text-left
				el-form()
					el-row.text-left
						h4 Reports - Platforms Integrations
						p This page contains the campaigns that are currently running on the platforms that we have integrated with. You can view the campaigns and their performance here as well as to add the geos.
					el-row.mt-2(:gutter="12")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Search...")
								el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Brand")
								el-select.w-100(filterable="" v-model="filters.brand_id" clearable placeholder="Select Brand" @change="fetchData")
									el-option(label="All Brands", :value="''")
									el-option(v-for="item in brand_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Plafroms")
								el-select.w-100(filterable="" v-model="filters.platform" clearable placeholder="Select Platform" @change="fetchData")
									el-option(label="All Platforms", :value="''")
									el-option(v-for="item in platform_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Funnel Family")
								el-select.w-100(filterable="" v-model="filters.funnel_family_id" clearable placeholder="Select Funnel Family" @change="fetchData")
									el-option(label="All Funnel Families", :value="''")
									el-option(v-for="item in funnel_family_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Country")
								el-select.w-100(filterable="" v-model="filters.country_iso" clearable placeholder="Select Country" @change="fetchData")
									el-option(label="All Countries", :value="''")
									el-option(v-for="item in country_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6" :md="4")
							el-form-item(label="Language")
								el-select.w-100(filterable="" v-model="filters.language_iso" clearable placeholder="Select Language" @change="fetchData")
									el-option(label="All Languages", :value="''")
									el-option(v-for="item in language_options" :key="item.v" :label="item.t" :value="item.v")
					el-divider
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")
						div
							el-tooltip(content="Bulk Update Campaigns")
								el-button(type="success", icon="el-icon-edit", size="small", @click="toggleMultipleCampaignUpdate" :disabled="multipleSelection.length === 0") Bulk Update
							el-tooltip(content="Upload Campaigns Spreadsheet - Update only")
								el-button(type="primary", icon="el-icon-upload2", size="small" @click="showUploadCampaignsModal = true") Upload
							el-tooltip(content="Download Campaigns Spreadsheet - All")
								el-button(type="warning", icon="el-icon-download", size="small" @click="downloadCampaignsExcel") Download

					el-table.mt-3(id="rep-cmp-tbl" v-loading="busy" stripe="" @selection-change="handleSelectionChange" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(type="selection" :width="55" )
						el-table-column(align="center" label="#" :width="200")
							template(slot-scope="scope")
								span() {{ scope.row.mongo_id }}
						el-table-column(align="center" label="External ID" :width="200")
							template(slot-scope="scope")
								span() {{ scope.row.ext_id }}
						el-table-column(align="center" label="Campaign Name")
							template(slot-scope="scope")
								span() {{ scope.row.name }}
						el-table-column(align="center" label="Platform Key" )
							template(slot-scope="scope")
								el-tooltip()
									template(slot="content")
										span.d-block() <b>Key</b>: {{ scope.row.platform.name }}
										span.d-block() <b>Family</b>: {{ scope.row.platform.family }}
									span.d-block.chooseable() <b>key</b>: {{ scope.row.platform.name }}
						el-table-column(align="center" label="Is Active")
							template(slot-scope="scope")
								font-awesome-icon.icon.alt(:icon="scope.row.is_active ? 'check' : 'times'" v-bind:class="{'text-success': scope.row.is_active, 'text-danger': !scope.row.is_active}")
						el-table-column(align="center" label="Belongs To")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.brand && scope.row.brand.user")
									template(slot="content")
										span.d-block <b>Username:</b> {{ scope.row.brand.user.username }}
										span.d-block <b>Full Name:</b> {{ scope.row.brand.user.first_name + ' ' + scope.row.brand.user.last_name }}
										span.d-block <b>Email:</b> {{ scope.row.brand.user.email }}
										span.d-block <b>ID:</b> {{ scope.row.brand.user.ext_id }}
									span.d-block.chooseable() {{ scope.row.brand.user.username }}
								span(v-else="") -
						// brand, funnel familym, country, language
						el-table-column(align="center" label="Brand" )
							template(slot-scope="scope")
								span() {{ scope.row.brand ? scope.row.brand.name : '-' }}
						el-table-column(align="center" label="Funnel Family" )
							template(slot-scope="scope")
								span() {{ scope.row.funnel_family ? scope.row.funnel_family.name : '-' }}
						el-table-column(align="center" label="Country" )
							template(slot-scope="scope")
								div(v-if="scope.row.country")
									span() {{ scope.row.country.name }}
									img.ml-2(style="width:20px" :src="require('../../assets/img/flags/' + scope.row.country.iso.toLowerCase() + '.svg')")
								div(v-else)
									| -
						el-table-column(align="center" label="Language" )
							template(slot-scope="scope")
								span(v-if="scope.row.language")
									| {{ scope.row.language.full }}
								span(v-else)
									i.text-info Unknown
						// actions
						el-table-column(align="center" label="Actions" :width="120")
							template(slot-scope="scope")
								el-tooltip(content="Show Campaign Insights")
									el-button.small-btn-action.plt-itg(type="success", icon="el-icon-pie-chart", size="small", @click="showInsights(scope.row.mongo_id)")
								el-tooltip(content="Edit campaign properties")
									el-button.small-btn-action.plt-itg(type="info", icon="el-icon-edit", size="small" @click="editCampaign(scope.row)")

								//el-button(content=""type="text" size="small" @click="editCampaign(scope.row)" icon="el-icon-edit" circle plain title="Edit Campaign")
								//el-button(type="text" size="small" @click="viewCampaign(scope.row)" icon="el-icon-search" circle plain title="View Campaign")
								//el-button(type="text" size="small" @click="deleteCampaign(scope.row)" icon="el-icon-delete" circle plain title="Delete Campaign")
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

						el-row.mt-4
							el-col(:span="24")
								label.label-text-gray
									b Total:&nbsp;
									| {{ count | numeral('0,0') }}

						el-row
							el-col(:span="24")
								el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
									el-option(label="5 Per Page", :value="5")
									el-option(label="10 Per Page", :value="10")
									el-option(label="20 Per Page", :value="20")
									el-option(label="50 Per Page", :value="50")
									el-option(label="100 Per Page", :value="100")
									el-option(label="200 Per Page", :value="200")
									el-option(label="500 Per Page", :value="500")
		#report-campaigns-modals

			el-dialog.override-width(title="Upload Campaigns Spreadsheet - Update only" :visible.sync="showUploadCampaignsModal"  center='' @closed="showUploadCampaignsModal = false")
				// I need a Drag to upload component here to load a single file, not bigger then 32mb, element ui
				el-row(:gutter="24")

					el-col(:xs="24" :lg="10" :xl="12")
						p.chooseable(@click="openWindow")
							b Download the template
							el-icon(name="el-icon-download")
						p.letter-long Upload a spreadsheet with the campaigns that you want to update. The spreadsheet should contain the following columns:&nbsp;
							b
								i.text-info mongo_id,&nbsp;
							b
								i.text-info brand_id,&nbsp;
							b
								i.text-info funnel_family_id,&nbsp;
							b
								i.text-info country_iso,&nbsp;
							b
								i.text-info language_iso,&nbsp;
							b
								i.text-info is_active

						p.letter-long
							span.text-danger.letter-long
								b Use with care!&nbsp;
							| This will update the campaigns that you have selected. If you want to update the insights as well, you can do so by selecting the option to override the insights. After the upload is done, <b>the process will start immidiately</b>.

						label.mt-3
							b.d-block Override Insights&nbsp;
								el-tooltip()
									template(slot="content")
										span.d-block() <b class="text-success">Yes</b>: Override the insights with the values from the spreadsheet
										span.d-block() <b class="text-warning">No</b>: Keep the insights as they are, unless they are empty
									el-icon.chooseable(name="question")
							el-switch.mt-2(v-model="file_override_insights" active-text="Yes" inactive-text="No")
					el-col(:xs="24" :lg="14" :xl="12")
						el-upload.upload-campaigns-file(
							drag="" :action="`${api_path}/bi/campaigns/upload?override=${file_override_insights}`" w thumbnail-mode="" :disabled="flist.length >= 1" v-bind:class="{'disabled-field': flist.length >= 5}" :on-success="handleSuccess" :on-error="handleError" :file-list="fileList" multiple="" :headers="auth" :limit="1" :before-upload="beforeUpload")
							i.el-icon-upload
							.el-upload__text
								| Drop file here or
								em click to upload
							.el-upload__tip(slot='tip') xls/xlsx files with a size less than 32MB - Max 1 file
						.d-flex.justify-content-center

			el-dialog.override-width.search-dialog(:title="'Edit Campaign Props ' + (action === 'single' ? ` - ${currentInstance ? currentInstance.name : ''} ` : ` - Multiple Selection (${multipleSelection.length})`)" :visible.sync="showEditCampaignPropsModal" , width='75%', center='' @closed="showEditCampaignPropsModal = false")
				el-row(:gutter="24")
					el-col(:span="24")
						p Edit the properties of the campaign that you have selected. Keep in mind that it will update the campaign insights too, unless you select not to override those that are already assigned.
						p.text-danger(v-if="action === 'multiple'")
							i Note: You have selected multiple campaigns. The changes will be applied to all of them.
				el-form()
					el-row(:gutter="24")
						// brand, funnel family, country, language
						el-col(:xs="12" :sm="6")
							el-form-item(label="Brand")
								el-select.w-100(filterable="" v-model="form.brand_id" clearable placeholder="Select Brand")
									el-option(label="Leave Current", :value="'::NULL::'")
									el-option(v-for="item in brand_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Funnel Family")
								el-select.w-100(filterable="" v-model="form.funnel_family_id" clearable placeholder="Select Funnel Family")
									el-option(label="Leave Current", :value="'::NULL::'")
									el-option(v-for="item in funnel_family_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Country")
								el-select.w-100(filterable="" v-model="form.country_iso" clearable placeholder="Select Country")
									el-option(label="Leave Current", :value="'::NULL::'")
									el-option(v-for="item in country_options" :key="item.v" :label="item.t" :value="item.v")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Language")
								el-select.w-100(filterable="" v-model="form.language_iso" clearable placeholder="Select Language")
									el-option(label="Leave Current", :value="'::NULL::'")
									el-option(v-for="item in language_options" :key="item.v" :label="item.t" :value="item.v")
					.d-flex.justify-content-center
						el-button(type="danger" @click="showEditCampaignPropsModal = false") Cancel
						el-button(type="success" @click="submit(0)" :disabled="disableWithoutSelection") Just Save
						el-button(type="warning" @click="submit(1)" :disabled="disableWithoutSelection") Save & Override

			el-dialog.override-width.search-dialog(:title="'Campaign Insights - ' + insights.currentCampaignId" :visible.sync='showInsightsModal', width='75%', center='' @closed="showInsightsModal = false")
				el-row.text-left(:gutter="24")
					el-col(:span="24")

						.justify-content-between.d-flex
							h5 These are the insights of the campaign that you have selected. You can view the performance of the campaign here.
							// download button inline
							el-tooltip(content="Download Insights Spreadsheet - All")
								el-button(type="warning", icon="el-icon-download", size="small" @click="downloadInsightsExcel") Download

				el-form()
					el-row.mt-3(:gutter="24")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Search...")
								el-input(placeholder="Search By Keyword..."  v-model="insights.paginate.keyword", v-debounce:300ms="fetchInsightsData")
						el-col(:xs="12" :sm="8")
							el-form-item(label="Date Range")
								el-date-picker.w-100(type="daterange" v-model="insights.paginate.date" range-separator="to" start-placeholder="Start Date" end-placeholder="End Date" @change="fetchInsightsData")
						//el-col(:xs="12" :sm="6")

				// the table will have the following columns: mongo_id, date, original_count, calculated_count, brand, funnel family, country, language, platform
				el-table(:data="insights.list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
					el-table-column(align="center" label="#" :width="200")
						template(slot-scope="scope")
							span() {{ scope.row.mongo_id }}
					el-table-column(align="center" label="Date")
						template(slot-scope="scope")
							span() {{ scope.row.date | formatDate }}
					el-table-column(align="center" label="Original Count")
						template(slot-scope="scope")
							span() {{ scope.row.original_count }}
					el-table-column(align="center" label="Calculated Count")
						template(slot-scope="scope")
							el-tooltip()
								template(slot="content")
									span.d-block() <b>Original</b>: {{ scope.row.original_count }}
									span.d-block() <b>Multipler</b>: {{ scope.row.multiplier }}
								span.d-block.chooseable() {{ scope.row.calculated_count }}
					el-table-column(align="center" label="Brand" )
						template(slot-scope="scope")
							span() {{ scope.row.brand ? scope.row.brand.name : '-' }}
					el-table-column(align="center" label="Funnel Family" )
						template(slot-scope="scope")
							span() {{ scope.row.funnel_family ? scope.row.funnel_family.name : '-' }}
					el-table-column(align="center" label="Country" )
						template(slot-scope="scope")
							div(v-if="scope.row.country")
								span() {{ scope.row.country.name }}
								img.ml-2(style="width:20px" :src="require('../../assets/img/flags/' + scope.row.country.iso.toLowerCase() + '.svg')")
							div(v-else)
								| -
					el-table-column(align="center" label="Language" )
						template(slot-scope="scope")
							span(v-if="scope.row.language")
								| {{ scope.row.language.full }}
							span(v-else)
								i.text-info Unknown
					el-table-column(align="center" label="Platform" )
						template(slot-scope="scope")
							span() {{ scope.row.platform.name }}
				.d-flex.justify-content-between
					b-pagination.mt-3(v-model="insights.paginate.page", :total-rows="insights.count", :per-page="insights.paginate.limit", aria-controls="data-list", @change="fetchInsightsData")

					el-row.mt-4
						el-col(:span="24")
							label.label-text-gray
								b Total:&nbsp;
								| {{ insights.count | numeral('0,0') }}

					el-row
						el-col(:span="24")
							el-select.mt-3(filterable="", v-model="insights.paginate.limit", @change="fetchInsightsData")
								el-option(label="5 Per Page", :value="5")
								el-option(label="10 Per Page", :value="10")
								el-option(label="20 Per Page", :value="20")
								el-option(label="50 Per Page", :value="50")
								el-option(label="100 Per Page", :value="100")
								el-option(label="200 Per Page", :value="200")
								el-option(label="500 Per Page", :value="500")
</template>


<style lang="scss">
.upload-campaigns-file {
	.el-upload-dragger {
		width: 100%
	}
	.el-upload.el-upload--text  {
		width: 100%
	}
}
</style>


<script>
import moment from "moment/moment";

export default {
	name: 'reports-campaigns',
	data() {
		return {
			busy: false,
			multipleSelection: [],
			block: false,
			showUploadCampaignsModal: false,
			showEditCampaignPropsModal: false,
			showInsightsModal: false,
			action: 'single',
			currentInstance: null,
			paginate: {
				keyword: '',
				limit: 10,
				page: 1
			},
			filters: {
				brand_id: '',
				funnel_family_id: '',
				country_iso: '',
				language_iso: '',
				date: [],
				is_active: -1
			},
			list: [],
			count: 0,
			insights: {
				currentCampaignId: null,
				list: [],
				count: 0,
				paginate: {
					keyword: '',
					limit: 10,
					page: 1,
					date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
				},
			},
			form: {
				brand_id: '::NULL::',
				funnel_family_id: '::NULL::',
				country_iso: '::NULL::',
				language_iso: '::NULL::',
			},
			platform_options: [],
			brand_options: [],
			funnel_family_options: [],
			country_options: [],
			language_options: [],
			fileList: [],
			flist: [],
			file_override_insights: [],
			loader: false
		}
	},
	mounted() {
		this.fetchData();
		this.fetchOptions();
	},
	computed: {
		api_path() {
			return process.env.VUE_APP_BASE_URL
		},
		auth() {
			return {Authorization: `Bearer ${this.$store.state.AUTH_TOKEN}`}
		},
		templateXlsx() {
			return process.env.VUE_APP_EXAMPLE_CAMPAIGN_XLSX
		},
		disableWithoutSelection() {
			return this.form.brand_id === '::NULL::' && this.form.funnel_family_id === '::NULL::' && this.form.country_iso === '::NULL::' && this.form.language_iso === '::NULL::';
		}
	},
	methods: {
		fetchData() {
			setTimeout(() => {
				const payload = { ...this.paginate, ...this.filters };
				this.$apix.sendHttpRequest('GET', 'bi/campaigns/list', payload)
						.then(response => {
							this.list = response.rows;
							this.count = response.count;
						})
			}, 500);

		},
		fetchOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', {filters: "59a7b7db-0258-410c-a0ba-ea4e639de60f,95e5744a-9964-42d4-ae26-c3e591ecb7e0,49f06cec-46b0-4237-bf9a-d4d3969e5af6,e91556b8-c5da-442f-a0bf-31f2ce567abe,b46a2973-5f33-4d57-8bc2-3eb96bb26ad2"})
					.then(response => {
						this.platform_options = response.pfi_platform_family;
						this.brand_options = response.brand;
						this.funnel_family_options = response.funnel_families;
						this.country_options = response.countries;
						this.language_options = response.languages;
					})
		},
		fetchInsightsData() {
			setTimeout(() => {
				const $params = { ...this.insights.paginate, id: this.insights.currentCampaignId};
				this.$apix.sendHttpRequest('GET', 'bi/campaigns/insights', $params)
						.then(response => {
							console.log(response);
							this.showInsightsModal = true;
							this.insights.list = response.rows;
							this.insights.count = response.count;
						})
			}, 800);
		},
		toggleMultipleCampaignUpdate() {
			this.action = 'multiple';
			this.showEditCampaignPropsModal = true;
		},
		downloadCampaignsExcel() {
			const $params = { keyword: this.insights.paginate.keyword, ...this.filters };
			return new Promise((resolve, reject) => {
				this.$http.get('bi/campaigns/list-xlsx', {params: $params}, {responseType: 'arraybuffer'})
						.then(response => {

							let decode = new Buffer(response.body, 'base64')
							let blob = new Blob([decode], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
									url = window.URL.createObjectURL(blob)
							window.open(url)
							this.$notify.success({title: 'API Response', message: 'Download succeeded'})
						})
						.catch(e => {
							console.error(['e'], e);
							this.$notify.error({
								title: 'API Error',
								message: e.body ? e.body.msg : e.message || 'Could not download spreadsheet'
							})
						})
			});
		},
		downloadInsightsExcel() {
			setTimeout(() => {
				const $params = { keyword: this.insights.paginate.keyword, date: this.insights.paginate.date, id: this.insights.currentCampaignId};
				return new Promise((resolve, reject) => {
					this.$http.get('bi/campaigns/insights-xlsx', {params: $params}, {responseType: 'arraybuffer'})
							.then(response => {

								let decode = new Buffer(response.body, 'base64')
								let blob = new Blob([decode], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
										url = window.URL.createObjectURL(blob)
								window.open(url)
								this.$notify.success({title: 'API Response', message: 'Download succeeded'})
							})
							.catch(e => {
								console.error(['e'], e);
								this.$notify.error({
									title: 'API Error',
									message: e.body ? e.body.msg : e.message || 'Could not download spreadsheet'
								})
							})
				});
			}, 800);
		},
		showInsights(campaignId) {
			this.insights.currentCampaignId = campaignId;
			this.fetchInsightsData();
		},
		editCampaign(campaign) {
			this.currentInstance = campaign;
			this.showEditCampaignPropsModal = true;
		},
		viewCampaign(campaign) {

		},
		handleSelectionChange(val) {
			this.block =  val.length > 0
			this.multipleSelection = val;
		},
		uncheckSelection() {
			this.multipleSelection = [];
			this.block = false;
		},
		submit(override) {
			if(this.action === 'single') {
				const payload = { ...this.form, id: this.currentInstance.mongo_id, override };
				this.$apix.sendHttpRequest('PUT', 'bi/campaigns/props', payload)
						.then(response => {
							this.$notify.success({
								title: 'Success',
								message: 'Campaign properties have been updated successfully.'
							});
							this.showEditCampaignPropsModal = false;
							this.fetchData();
						})
			} else if (this.action === 'multiple') {
				const payload = { ...this.form, ids: this.multipleSelection.map(item => item.mongo_id), override };
				this.$apix.sendHttpRequest('PUT', 'bi/campaigns/props-bulk', payload)
						.then(response => {
							this.$notify.success({
								title: 'Success',
								message: 'Campaign properties have been updated successfully.'
							});
							this.showEditCampaignPropsModal = false;
							this.fetchData();
						})
			}
		},
		handleSuccess(response, file, fileList) {
			this.$notify.success({
				title: 'Success',
				message: 'Campaigns have been updated successfully.'
			});
			this.showUploadCampaignsModal = false;
			this.fetchData();
			this.loader.hide();
		},
		handleError(err) {
			this.$notify.error({
				title: 'Error',
				message: err.body ? err.body.msg : err.message || 'Could not upload spreadsheet'
			});
			this.loader.hide();
		},
		beforeUpload(file) {
			if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
				this.$message.error('Document file must be a spreadsheet!');
				return false
			}

			if (file.size / 1024 / 1024 > 32) {
				this.$message.error('Document file size can not exceed 32MB!');
				return false
			}
			this.loader = this.$loading.show();
		},
		openWindow() {
			window.open(this.templateXlsx, '_blank');
		}
	}
}
</script>